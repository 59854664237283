<template>
  <b-card
    v-if="data"
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            <div class="mb-1">
              Revenue Report
            </div> <lenon-select
              v-model="selectedYear"
              class="mt-1"
              :options="academicYears"
              label-name="name"
              value-name="id"
              placeholder="Select Academic Year"
            />
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer" />
              <span>Earning({{ currencySymbol }})</span>
            </div>
            <div class="d-flex align-items-center ml-75">
              <span class="bullet bullet-danger svg-font-small-3 mr-50 cursor-pointer" />
              <span>Expense({{ currencySymbol }})</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          v-if="loaded"
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="revenueReport.chartOptions"
          :series="data.revenueReport.series"
        />
        <vue-apex-charts
          v-else
          id="revenue-report-chart"
          type="bar"
          height="230"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import LenonSelect from '@/lenon/components/LenonSelect'

export default {
  components: {
    LenonSelect,
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      revenue_report: {},
      selectedYear: null,
    }
  },
  computed: {
    ay() {
      return this.$store.getters['auth/academicYear']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    revenueReport() {
      const symbol = this.currencySymbol
      return {
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            formatter(val, opts) {
              return `${symbol}${val.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
            },
            style: {
              fontSize: '12px',
            },
            background: {
              enabled: true,
              foreColor: '#fff',
              padding: 4,
              borderRadius: 2,
              borderWidth: 1,
              borderColor: '#fff',
              opacity: 0.9,
              dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45,
              },
            },
          },
          colors: [$themeColors.success, $themeColors.danger],
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
          },
        },
      }
    },
    currencySymbol() {
      return process.env.VUE_APP_CURRENCY
    },
  },
  watch: {
    selectedYear(val) {
      this.$emit('onLoad', val)
    },
  },
}
</script>
