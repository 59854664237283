<template>
  <b-row class="match-height">
    <!--    <b-col lg="4">-->
    <!--      <b-row class="match-height">-->

    <!--        <b-col-->
    <!--          lg="12"-->
    <!--          md="6"-->
    <!--        >-->
    <!--          <ecommerce-earnings-chart :data="data.earningsChart" />-->
    <!--        </b-col>-->
    <!--        &lt;!&ndash; Bar Chart - Orders &ndash;&gt;-->
    <!--        <b-col-->
    <!--          lg="12"-->
    <!--          md="6"-->
    <!--          cols="12"-->
    <!--        >-->
    <!--          <fee-arrears />-->
    <!--        </b-col>-->
    <!--      </b-row>-->
    <!--    </b-col>-->

    <!-- Revenue Report Card -->
    <b-col lg="12">
      <b-overlay
        :show="loading"
        :variant="darkMode?'dark':'white'"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <ecommerce-revenue-report
          :loading="loading"
          :loaded="loaded"
          :data="data.revenue"
          @onLoad="onLoadRevenue"
        />
      </b-overlay>
    </b-col>
    <!--/ Revenue Report Card -->
  </b-row>
</template>

<script>
import { BRow, BCol, BOverlay } from 'bootstrap-vue'
import EcommerceEarningsChart from '@/views/dashboard/finance/EcommerceEarningsChart.vue'
import EcommerceRevenueReport from '@/views/dashboard/finance/EcommerceRevenueReport.vue'
import FeeArrears from '@/views/dashboard/finance/FeeArrears'
import useAppConfig from '@core/app-config/useAppConfig'

const { skin } = useAppConfig()
export default {
  name: 'Arrears',
  components: {
    FeeArrears,
    EcommerceRevenueReport,
    EcommerceEarningsChart,
    BRow,
    BCol,
    BOverlay,
  },
  props: {
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      loaded: false,
      data: {
        earningsChart: {
          series: [53, 16, 31],
        },
        revenue: {
          revenueReport: {
            series: [
              {
                name: 'Earning',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
              {
                name: 'Expense',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
            ],
          },
        },
      },
    }
  },
  computed: {
    darkMode() {
      return skin.value === 'dark'
    },
    revenue() {
      return this.$store.getters['dashboard/finance']?.revenueReport
    },
  },
  watch: {
    revenue(val) {
      this.data.revenue.revenueReport.series[0].data = val.earnings
      this.data.revenue.revenueReport.series[1].data = val.expenses
      this.loaded = true
    },
  },
  methods: {
    onLoadRevenue(ay) {
      this.loaded = false
      this.$emit('onLoad', ay)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
