<template>
  <div v-if="hasModule('finance')">
    <b-row class="match-height">
      <b-col
        lg="12"
        cols="12"
      >
        <payments
          title="Overall"
          :payments="payments"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
          lg="12"
          cols="12"
      >
        <payments
            title="Current Academic Year"
            :payments="paymentsForCurrentYear"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="12"
        cols="12"
      >
        <payments
          title="Previous Academic Year"
          :payments="paymentsForPrevYear"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
          lg="12"
          cols="12"
      >
        <payments
            title="Current Term"
            :payments="paymentsForCurrentTerm"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="12"
        cols="12"
      >
        <payments
          title="Previous Term"
          :payments="paymentsForPrevTerm"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="12"
        cols="12"
      >
        <arrears
          :loading="loadingRevenue"
          @onLoad="onLoadRevenue"
        />
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <b-card>
      <h4 class="text-center">
        You do not have access to Finance Module
      </h4>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import Arrears from '@/views/dashboard/finance/Arrears'
import { READ_FINANCE_DASHBOARD_DATA_Q, GET_REVENUE_FOR_YEAR_Q } from '@/graphql/queries'
import logData from '@/libs/log'
import Payments from './Payments.vue'

export default {
  name: 'FinanceDashboard',
  components: {
    Arrears,
    Payments,
    BRow,
    BCol,
  },
  data() {
    return {
      // Area charts
      subscribersGained: {},
      revenueGenerated: {},
      quarterlySales: {},
      ordersRecevied: {},

      // Line Charts
      siteTraffic: {},
      activeUsers: {},
      newsletter: {},
      loadingRevenue: false,
    }
  },
  computed: {
    payments() {
      return this.$store.getters['dashboard/finance']?.paymentSummary
    },
    paymentsForCurrentYear() {
      return this.$store.getters['dashboard/finance']?.paymentsForCurrentYear
    },
    paymentsForCurrentTerm() {
      return this.$store.getters['dashboard/finance']?.paymentsForCurrentTerm
    },
    paymentsForPrevYear() {
      return this.$store.getters['dashboard/finance']?.paymentsForPrevYear
    },
    paymentsForPrevTerm() {
      return this.$store.getters['dashboard/finance']?.paymentsForPrevTerm
    },
  },
  mounted() {
    this.fetchStudentDashboardData()
  },
  methods: {
    hasModule(module) {
      const modules = this.$store.getters['auth/schoolModules']
      return modules.includes(module)
    },
    kFormatter,
    fetchStudentDashboardData() {
      this.$apollo.query({
        query: READ_FINANCE_DASHBOARD_DATA_Q,
        variables: { year: null },
      })
        .then(res => {
          if (!res.errors && res.data != null) {
            this.$store.commit('dashboard/setFinanceDashboardData', res.data)
          }
        })
        .catch(err => {
          logData(err)
        })
    },
    onLoadRevenue(id) {
      this.loadingRevenue = true
      this.$apollo.query({
        query: GET_REVENUE_FOR_YEAR_Q,
        variables: { ay_id: id },
      })
        .then(res => {
          if (!res.errors && res.data != null) {
            this.$store.commit('dashboard/setRevenue', res.data)
          }
        })
        .catch(err => {
          logData(err)
        }).finally(() => {
          this.loadingRevenue = false
        })
    },
  },
}
</script>
