<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title v-if="payments && payments.title">{{ title }} ({{ payments.title }})</b-card-title>
      <b-card-title v-else>{{ title }}</b-card-title>
      <b-card-text class="mr-25 mb-0">
        <!--        August 4th 2022, 7:09 AM-->
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row v-if="payments">
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ currencySymbol }}{{ item.title | currency }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <b-row v-else>
        <strong>Data Not Available</strong>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    payments: { type: Array, default: () => [] },
    title: { type: String, default: 'Payments' },
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'PlusIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Fees',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'PlusIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Other Income',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'MinusIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Expenses',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'FileTextIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Balance',
          customClass: '',
        },
      ],
    }
  },
  computed: {
    currencySymbol() {
      return process.env.VUE_APP_CURRENCY
    },
  },
  watch: {
    payments(val) {
      if (val) {
        this.statisticsItems[0].title = val.total_fees + val.recurring
        this.statisticsItems[1].title = val.other_income
        this.statisticsItems[2].title = val.total_expenses
        this.statisticsItems[3].title = val.balance
      }
    },
  },
}
</script>
